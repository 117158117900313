import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    companies: [],
  },
  mutations: {
    user(state, data) {
      state.user = data;
    },
    setCompanies(state, data) {
      state.companies = data;
    },
  },
  actions: {},
  modules: {},
});
