import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        // primary: "#2c3e50",
        primary: "#363636",
        // primary: "#22487d",
        secondary: "#7D2E5F",
        success: "#00c853",
        error: "#d50000",
      },
      dark: {
        // primary: "#22487d",
        secondary: "#7D2E5F",
        success: "#00c853",
        error: "#d50000",
      },
    },
  },
});
