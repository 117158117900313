<template>
  <div id="app">
    <router-view />
    <v-snackbar bottom right :value="updateExists" :timeout="-1">
      An update is available.
      <template v-slot:action>
        <v-btn text @click="refreshApp"> Update </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import update from "./mixins/update";

export default {
  name: "App",
  data: () => ({}),
  mixins: [update],
};
</script>

<style lang="scss">
$primary: #22487d;
$secondary: #0059ff46;

#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // background-color: #136d880e;
  // background-image: linear-gradient(to bottom, #22487d1c, $secondary);
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
