import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/LoginPage"),
    children: [
      { path: "/", component: () => import("@/components/login") },
      {
        path: "forgot-password",
        component: () => import("@/components/login/ForgotPassword"),
      },
    ],
  },
  {
    path: "/company-list",
    component: () => import("@/components/login/CompanyList"),
  },
  {
    path: "/",
    component: () => import("@/views/HomePage"),
    redirect: "workorders",
    children: [
      {
        path: "workorders",
        component: () => import("@/components/workorders/index.vue"),
        children: [
          {
            path: "",
            component: () => import("@/components/workorders/All"),
          },
          {
            path: "pending",
            component: () => import("@/components/workorders/Pending"),
          },
          {
            path: "complete",
            component: () => import("@/components/workorders/Delivered"),
          },
        ],
      },
      {
        path: "inventory",
        component: () => import("@/components/inventory/index.vue"),
        children: [
          {
            path: "",
            redirect: "stock",
          },
          {
            path: "stock",
            component: () => import("@/components/inventory/Inventory"),
          },
          {
            path: "transactions",
            component: () => import("@/components/inventory/Transactions"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
